import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';

const initialState: any = {
    teamMasterList: [],
    teamMasterListAll: [],
    page: 1,
    limit: 10,
    totalCount: 0,
    selectedTeamMaster: null,
    loader: false,
    cloneLoader: false,
    deleteRecId: null,
    publicProjectTotalCount: 0
};

const slice = createSlice({
    name: 'teamMaster',
    initialState,
    reducers: {
        getTeamLeaderList(state: any, action: PayloadAction<{ teamMasterList: any[], totalCount: number }>) {
            state.teamMasterList = action.payload.teamMasterList;
            state.totalCount = action.payload.totalCount;
        },
        getTeamLeaderDropdownList(state: any, action: PayloadAction<{ teamMasterListAll: any[], totalCount: number }>) {
            state.teamMasterListAll = action.payload.teamMasterListAll;
            state.totalCount = action.payload.totalCount;
        },
        getSingleData(state: any, action: PayloadAction<{ selectedTeamMaster: any }>) {
            state.selectedTeamMaster = action.payload.selectedTeamMaster
        },
        loader(state: any, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: any, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
    }
});

export const reducer = slice.reducer;

export const getAllTeamMaster = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    let pageV = page * limit
    const response: any = await AXIOS_GET(`/api/team-master/get-all?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});
    if (response) {
        dispatch(slice.actions.getTeamLeaderList({ teamMasterList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }
    dispatch(slice.actions.loader({ loader: false }));
};

export const getAllDropdownTeamMaster = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/team-master/_all`, {}, {});
    if (response) {
        dispatch(slice.actions.getTeamLeaderDropdownList({ teamMasterListAll: response?.data || [], totalCount: response?.totalCount || 0 }))
    }
    dispatch(slice.actions.loader({ loader: false }));
    return response
};

export const saveTeamMaster = (payload: any, history: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/team-master/create-team', payload, {});
    if (response?.data) {
        dispatch(slice.actions.getSingleData({ selectedTeamMaster: response?.data }));
        toastr.success('Success', response?.message);
        history.replace(`/app/team-master`);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateTeamLeader = (id, payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/team-master/update/${id}`, payload, {});
    if (response?.data?.id) {
        await dispatch(slice.actions.getSingleData({ selectedTeamMaster: null }))
        toastr.success('Success', response?.message);
        history.replace(`/app/team-master`);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getTeamMasterById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/team-master/' + id, {}, {});
    if (response) {
        dispatch(slice.actions.getSingleData({ selectedTeamMaster: response?.data }))
        return response?.data
    }
};

export const deleteTeamMasterById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/team-master/delete/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));
    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted || false;
};

/* #region custom functions */
export const setDeleteRecordId = (id: number) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}


export const resetEntireForms = () => (dispatch) => {
    dispatch(slice.actions.getSingleData({ selectedTeamMaster: null }))
}

export default slice;
