import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction, current } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { QAStateData } from 'src/types/QA';

const initialState: QAStateData = {
    departmentList: [],
    departmentTotalCount: 0,
    skillList: [],
    skillTotalCount: 0,
    categorizedTopicDetails: {},
    questionList: [],
    questionTotalCount: 0,
    openedAccordionSkillId: null,
    creationLoader: false,
    selectedTopicId: null
};

const slice = createSlice({
    name: 'knowledgeBase',
    initialState,
    reducers: {
        setDepartmentList(state: QAStateData, action: PayloadAction<{ departmentList: any[], departmentTotalCount: number }>) {
            state.departmentList = action.payload.departmentList;
            state.departmentTotalCount = action.payload.departmentTotalCount;
        },
        setSkillList(state: QAStateData, action: PayloadAction<{ skillList: any[], skillTotalCount: number, clear: boolean }>) {
            const currentSkillList = current(state).skillList;
            state.skillTotalCount = action.payload.skillTotalCount;
            if (action.payload.clear) {
                state.skillList = action.payload.skillList;
            } else {
                state.skillList = [...currentSkillList, ...action.payload.skillList];
            }
        },
        setTopicList(state: QAStateData, action: PayloadAction<{ topicList: any[], topicTotalCount: number, skillId: number | string, clear: boolean }>) {
            let skillId = action.payload.skillId;
            let currentCategorizedTopicDetails: any = { ...current(state).categorizedTopicDetails };
            let topicCatDetail = {...currentCategorizedTopicDetails[skillId]};
            let topicList: any[] = topicCatDetail?.topicList || [];

            if (action.payload.clear) {
                topicList = [...action.payload.topicList];
            } else {
                topicList = [...topicList, ...action.payload.topicList]
            }

            topicCatDetail.topicList = [...topicList]
            topicCatDetail.topicTotalCount = action.payload.topicTotalCount;
            currentCategorizedTopicDetails[skillId] = { ...topicCatDetail };
            state.categorizedTopicDetails = { ...currentCategorizedTopicDetails };
        },
        setQuestionList(state: QAStateData, action: PayloadAction<{ questionList: any[], questionTotalCount: number, clear: boolean }>) {
            const currentQuestionList = current(state).questionList;
            state.questionTotalCount = action.payload.questionTotalCount;

            if (action.payload.clear) {
                state.questionList = action.payload.questionList;
            } else {
                state.questionList = [...currentQuestionList, ...action.payload.questionList];
            }
        },
        appendNewDepartmentItemIntoDepartmentList(state: QAStateData, action: PayloadAction<{ departmentObject: any }>) {
            let currentDepartmentList: any[] = [...current(state).departmentList];
            let currentDepartmentTotalCount: number = current(state).skillTotalCount;
            let currentDepartmentObject: any = { ...action.payload.departmentObject };
            let finalDepartmentArray: any[] = [...currentDepartmentList, { label: currentDepartmentObject?.name || '', value: currentDepartmentObject?.id || null }];
            state.departmentList = [...finalDepartmentArray];
            state.departmentTotalCount = currentDepartmentTotalCount + 1;
        },
        appendNewSkillItemIntoSkillList(state: QAStateData, action: PayloadAction<{ skillObject: any }>) {
            let currentSkillList: any[] = [...current(state).skillList];
            let currentSkillTotalCount: number = current(state).skillTotalCount;
            let currentSkillObject: any = { ...action.payload.skillObject };
            let finalSkillArray: any[] = [...currentSkillList, currentSkillObject];
            state.skillList = [...finalSkillArray];
            state.skillTotalCount = currentSkillTotalCount + 1;
        },
        appendNewTopicItemIntoTopicList(state: QAStateData, action: PayloadAction<{ topicObject: any, skillId: number }>) {
            let currentCategorizedTopicDetails: any = { ...current(state).categorizedTopicDetails };
            let topicCatDetail = { ...currentCategorizedTopicDetails[Number(action.payload.skillId)] };
            topicCatDetail.taskList = [...topicCatDetail.taskList, action.payload.topicObject];
            topicCatDetail.topicTotalCount = currentCategorizedTopicDetails[Number(action.payload.skillId)].topicTotalCount + 1;
            currentCategorizedTopicDetails[Number(action.payload.skillId)] = {...topicCatDetail};
            state.categorizedTopicDetails = currentCategorizedTopicDetails;
        },
        setCreationLoader(state: QAStateData, action: PayloadAction<any>) {
            state.creationLoader = action.payload.creationLoader
        },
        setOpenedAccordionSkillId(state: QAStateData, action: PayloadAction<any>) {
            state.openedAccordionSkillId = action.payload.openedAccordionSkillId
        },
        setSelectedTopicId(state: QAStateData, action: PayloadAction<any>) {
            state.selectedTopicId = action.payload.selectedTopicId
        },
           // setDeleteRecordId(state: QAStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
        //     state.deleteRecId = action.payload.deleteRecId
        // },
        // setKnowledgebaseSingleData(state: QAStateData, action: PayloadAction<{ selectedKnowledgeBaseObject: any }>) {
        //     state.selectedKnowledgeBaseObject = action.payload.selectedKnowledgeBaseObject
        // },
        // loader(state: QAStateData, action: PayloadAction<any>) {
        //     state.loader = action.payload.loader
        // },
        // setCategoryDropdownList(state: QAStateData, action: PayloadAction<{ knowledgeBaseDropdownList: any[] }>) {
        //     state.categoryDropdownList = action.payload.knowledgeBaseDropdownList
        // },
        // setDeleteRecordId(state: QAStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
        //     state.deleteRecId = action.payload.deleteRecId
        // },
        // setPreviewData(state: QAStateData, action: PayloadAction<{ previewData: any }>) {
        //     state.previewData = action.payload.previewData
        // }
    }
});

export const reducer = slice.reducer;

export const getDepartmentList = (page: number = 1, limit: number = 50, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/department?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let departmentList: any[] = [];
    let departmentTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let deptList: any[] = [...response?.data[0]];
        departmentTotalCount = response?.data[1] || 0;

        departmentList = deptList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }

    if (!searchKey) {
        dispatch(slice.actions.setDepartmentList({ departmentList: departmentList, departmentTotalCount: departmentTotalCount }))
    }

    return { departmentList: departmentList || [], departmentTotalCount: response?.data && response?.data[1] || 0 }

};

export const getSkillList = (page: number = 1, limit: number = 50, searchKey: string = '', departmentId: string = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/skill?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}`, {}, {});

    dispatch(slice.actions.setSkillList({
        skillList: response?.data && response?.data.length ? response?.data[0] : [],
        skillTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));
};

export const getTopicList = (page: number = 1, limit: number = 2, searchKey: string = '', skillId: string = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/topic?page=${page}&limit=${limit}&searchKey=${searchKey}&skill=${skillId}`, {}, {});

    dispatch(slice.actions.setTopicList({
        topicList: response?.data && response?.data.length ? response?.data[0] : [],
        topicTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        skillId: skillId,
        clear
    }));
};

export const getQuestionList = (page: number = 1, limit: number = 10, searchKey: string = '', topicId: number | string = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/question?page=${page}&limit=${limit}&searchKey=${searchKey}&topic=${topicId}`, {}, {});

    dispatch(slice.actions.setQuestionList({
        questionList: response?.data && response?.data.length ? response?.data[0] : [],
        questionTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        // topicId: topicId,
        clear
    }));
};

// export const getQuestionById = (id): AppThunk => async (dispatch) => {
//     const response: any = await AXIOS_GET('/api/question/' + id, {}, {});

//     if (response?.status == true) {
//         dispatch(slice.actions.setKnowledgebaseSingleData({ selectedKnowledgeBaseObject: response?.data || null }))
//     }
// };

export const createDepartment = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));

        const response: any = await AXIOS_POST('/api/department', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, { variant: 'success' });
            dispatch(slice.actions.appendNewDepartmentItemIntoDepartmentList({ departmentObject: response?.data }));

        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.data?.id ? true : false;

    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

export const createSkill = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/skill', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, { variant: 'success' });
            dispatch(slice.actions.appendNewSkillItemIntoSkillList({ skillObject: response?.data }));

        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

export const createTopic = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/topic', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, { variant: 'success' });
            // dispatch(slice.actions.appendNewTopicItemIntoTopicList({ topicObject: response?.data, skillId: payload?.skill || null }));
        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};


// export const updateKnowledgeBase = (id, payload): AppThunk => async (dispatch) => {
//     const response: any = await AXIOS_PATCH(`/api/knowledgebase/${id}`, payload, {});

//     if (response?.data?.id) {
//         toastr.success('Success', response?.message);
//         dispatch(slice.actions.setKnowledgebaseSingleData({ selectedKnowledgeBaseObject: response?.data }))
//     } else {
//         toastr.error('Error', response?.message);
//     }
// };

// export const deleteKnowledgebaseById = (knowledgeBaseList, id): AppThunk => async (dispatch) => {
//     const response: any = await AXIOS_DELETE('/api/knowledgebase/' + id, {});
//     dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

//     if (response?.data?.isDeleted) {
//         toastr.success('Success', response?.message);
//     } else {
//         toastr.error('Error', response?.message);
//     }

//     return response?.data?.isDeleted == true ? true : false;

// };

// export const getCategoryDropdownList = (skip: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
//     const response: any = await AXIOS_GET(`/api/knowledgebase/category?page=${skip}&limit=${limit}&searchKey=${searchKey}`, {}, {});

//     if (response) {
//         dispatch(slice.actions.setCategoryDropdownList({ knowledgeBaseDropdownList: response?.data || [] }));
//     }
// };

// export const getKnowledgebasePreviewById = (id): AppThunk => async (dispatch) => {
//     const response: any = await AXIOS_GET(`/api/knowledgebase/${id}/preview`, {}, {});

//     if (response?.status == true) {
//         dispatch(slice.actions.setPreviewData({ previewData: response?.data?.htmlContent || null }))
//     } else {
//         toastr.error('Error', response?.message);
//     }
// };

/* #region custom functions */
// export const setDeleteRecordId = (id) => (dispatch) => {
//     dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
// }

export const setOpenedAccordionSkillId = (id) => (dispatch) => {
    dispatch(slice.actions.setOpenedAccordionSkillId({ openedAccordionSkillId: id ? id : null }));
}

export const setSelectedTopicId = (id) => (dispatch) => {
    dispatch(slice.actions.setSelectedTopicId({ selectedTopicId: id ? id : null }));
}

export const clearSkillList = () => (dispatch) => {
    dispatch(slice.actions.setSkillList({
        skillList: [], skillTotalCount: 0, clear: true
    }));
}
/* #endregion custom functions */

export default slice;
