import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { sideBarSKilsStateData } from 'src/types/sideBarSkils';

const initialState: sideBarSKilsStateData = {
    sideBarOpen: false
};

const slice = createSlice({
    name: 'sideBarSkils',
    initialState,
    reducers: {
        setSideBarOpenState(state: sideBarSKilsStateData, action: PayloadAction<any>) {
            state.sideBarOpen = action.payload.sideBarOpen
        },
    }
});

export const reducer = slice.reducer;

/* #region custom functions */
export const setSideBarOpenState = (status) => (dispatch) => {
    dispatch(slice.actions.setSideBarOpenState({ sideBarOpen: status == true ? true : false }));
}
/* #endregion custom functions */

export default slice;
