import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { MailStateData } from 'src/types/mailSetting';
import {toastr} from 'react-redux-toastr';

const initialState: MailStateData = {
    mailList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedMailObject: null,
    loader: false,
    deleteRecId: null
};

const slice = createSlice({
    name: 'mail-setting',
    initialState,
    reducers: {
        getMailSettingList(state: MailStateData, action: PayloadAction<{ mailList: any[], totalCount: number }>) {
            state.mailList = action.payload.mailList;
            state.totalCount = action.payload.totalCount;
        },
        getMailSettingSingleData(state: MailStateData, action: PayloadAction<{ selectedMailObject: any }>) {
            state.selectedMailObject = action.payload.selectedMailObject;      
        },
        loader(state: MailStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: MailStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        deleteListRecordFromStateById(state: MailStateData, action: PayloadAction<{ mailList: any[], deleteRecId: string | number }>) {
            let tempList: any[] = action.payload.mailList || [];
            let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
            state.mailList = finalPayloadList;
            state.totalCount = finalPayloadList.length;  
        }
    }
});

export const reducer = slice.reducer;

export const getAllMailSettings = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/mail-setting?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.getMailSettingList({ mailList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }
    dispatch(slice.actions.loader({ loader: false }));
};

export const saveMailSetting = (payload: any, history: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/mail-setting', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        history.replace(`/app/mail-setting/create-mail/${response?.data.id}`);
        dispatch(slice.actions.getMailSettingSingleData({ selectedMailObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateMailSetting = (id: number, payload: any, history: any): AppThunk => async (dispatch) => {    
    const response: any = await AXIOS_PATCH(`/api/mail-setting/${id}`, payload, {});
    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        await dispatch(slice.actions.getMailSettingSingleData({ selectedMailObject: response?.data }))
        history.replace(`/app/mail-setting`);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getMailSettingById = (id: number): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/mail-setting/' + id, {}, {});

    if (response) {
        dispatch(slice.actions.getMailSettingSingleData({ selectedMailObject: response.data }))
    }
};

export const deleteSettingById = (mailList: any, id: number | string): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/mail-setting/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));
    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }
    return response?.data?.isDeleted || false;
};

/* #region custom functions */
export const setDeleteRecordId = (id: number) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForms = () => (dispatch) => {
    dispatch(slice.actions.getMailSettingSingleData({ selectedMailObject: null }))
}
/* #endregion custom functions */


export default slice;
