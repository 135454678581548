import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  menu?: string | any;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];


export const renderRoutes = (routes: Routes = []): JSX.Element => (
  
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {

        let permissionModules: any = localStorage.getItem('permissionModules');
        if(permissionModules && permissionModules && route.routes && route.routes.length){
          permissionModules = permissionModules.split(',')
          permissionModules.push('Common')
          route.routes = route.routes.filter(item => {
            if (!item.menu) {
              return true
            }
            if(item.menu && permissionModules.includes(item.menu)){
              return true; 
            }
            
          });

          console.log(' afterrrrrrrrrrr ', route.routes)
        }

        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    menu: "Common",
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    menu: "Common",
    exact: true,
    // guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    menu: "Common",
    exact: true,
    // guard: GuestGuard,
    path: '/login/:id',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    menu: "Common",
    exact: true,
    // guard: GuestGuard,
    path: '/admin/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  // Forgot Password
  {
    menu: "Common",
    exact: true,
    // guard: GuestGuard,
    path: '/forgotpassword',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    menu: "Common",
    exact: true,
    // guard: GuestGuard,
    path: '/forgotpassword/:id',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      // Roles
      {
        menu: "Role",
        exact: true,
        path: '/app/role',
        component: lazy(() => import('src/views/roles/listView'))
      },

      // Master
      {
        menu: "Settings",
        exact: true,
        path: '/app/masters',
        component: lazy(() => import('src/views/masters'))
      },

      // Mail Setting
      {
        menu: "Mail Setting",
        exact: true,
        path: '/app/mail-setting',
        component: lazy(() => import('src/views/mail-setting/listView'))
      },

      // Team Master
      {
        menu: "Team Master",
        exact: true,
        path: '/app/team-master',
        component: lazy(() => import('src/views/team-master/listView'))
      },

      // Category
      {
        menu: "Settings",
        exact: true,
        path: '/app/category',
        component: lazy(() => import('src/views/category'))
      },
      // Attendance
      {
        menu: "Attendance",
        exact: true,
        path: '/app/attendance',
        component: lazy(() => import('src/views/attendance'))
      },
      {
        menu: "Attendance",
        exact: true,
        path: '/app/attendance-tl',
        component: lazy(() => import('src/views/attendanceTL'))
      },
      {
        menu: "Attendance View",
        exact: true,
        path: '/app/attendance-view',
        component: lazy(() => import('src/views/attendanceView'))
      },
      {
        menu: "Roster",
        exact: true,
        path: '/app/roster',
        component: lazy(() => import('src/views/attendanceRoster'))
      },
      // Employee
      {
        menu: "Employee",
        exact: true,
        path: '/app/employee',
        component: lazy(() => import('src/views/employee'))
      },
      {
        menu: "Exit Resource",
        exact: true,
        path: '/app/exited-employee',
        component: lazy(() => import('src/views/employeeExited'))
      },

      // Attendance Report
      {
        menu: "Reports",
        exact: true,
        path: '/app/attendance-report',
        component: lazy(() => import('src/views/attendaceReport'))
      },
      {
        menu: "Role",
        exact: true,
        path: '/app/role/create-role',
        component: lazy(() => import('src/views/roles/formView'))
      },

      {
        menu: "Role",
        exact: true,
        path: '/app/role/create-role/:id',
        component: lazy(() => import('src/views/roles/formView'))
      },

      {
        menu: "Mail Setting",
        exact: true,
        path: '/app/mail-setting/create-mail',
        component: lazy(() => import('src/views/mail-setting/formView'))
      },
      {
        menu: "Mail Setting",
        exact: true,
        path: '/app/mail-setting/create-mail/:id',
        component: lazy(() => import('src/views/mail-setting/formView'))
      },
      {
        menu: "Team Master",
        exact: true,
        path: '/app/team-master/create-team',
        component: lazy(() => import('src/views/team-master/formView'))
      },
      {
        menu: "Team Master",
        exact: true,
        path: '/app/team-master/create-team/:id',
        component: lazy(() => import('src/views/team-master/formView'))
      },
      {
        menu: "Common",
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },    
      {
        menu: "Common",
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/attendance" />
      },
      {
        menu: "Common",
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/attendance-tl" />
      },
      {
        component: () => <Redirect to="/404" />
      },

  
    ]
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        menu: "Common",
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/attendance" />
      },
      {
        menu: "Common",
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/attendance-tl" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
