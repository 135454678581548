import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { UserStateData } from 'src/types/users';
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { me } from './auth';
import { snackbarGlobConfig } from 'src/globalConfig';
import _ from 'lodash';

const initialState: UserStateData = {
    userList: [],
    roleList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedUserObject: null,
    loader: false,
    deleteRecId: null,
    agentModalOpenStatus: false,
    masters: null,
    selectedEmployee: null,
    attendanceModalOpenStatus: false,
    atncCommentsLoader: false,
    atncLeaveStatsLoader: false,
    atncCommentsList: [],
    atncLeaveStatsList: [],
    selectedEmployeeObject: null,
    exitedUserList: [],
    exitedUserListTotalCount: 0,
    selectedExitedEmployee: null,
    exitedEmployeeModalOpenStatus: false,
    projectMasterList: [],
    shiftMasterList: [],
    rosterMasterList: [],
    uploadCsvLoader: false,
    uploadedBulkEmployeeResponseObject: null,
    uploadedBulkPhotoObject: null
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsersList(state: UserStateData, action: PayloadAction<{ userList: any[], totalCount: number }>) {
            state.userList = action.payload.userList;
            state.totalCount = action.payload.totalCount;
        },
        setExitedUsersList(state: UserStateData, action: PayloadAction<{ exitedUserList: any[], totalCount: number }>) {
            state.exitedUserList = action.payload.exitedUserList;
            state.exitedUserListTotalCount = action.payload.totalCount;
        },
        getUserSingleData(state: UserStateData, action: PayloadAction<{ selectedUserObject: any }>) {
            state.selectedUserObject = action.payload.selectedUserObject
        },
        setAgentModalOpenStatus(state: UserStateData, action: PayloadAction<{ agentModalOpenStatus: boolean }>) {
            state.agentModalOpenStatus = action.payload.agentModalOpenStatus
        },
        setExitedEmployeeModalOpenStatus(state: UserStateData, action: PayloadAction<{ exitedEmployeeModalOpenStatus: boolean }>) {
            state.exitedEmployeeModalOpenStatus = action.payload.exitedEmployeeModalOpenStatus
        },
        loader(state: UserStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        getRoleList(state: UserStateData, action: PayloadAction<{ roleList: any[] }>) {
            state.roleList = action.payload.roleList
        },
        setDeleteRecordId(state: UserStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        deleteListRecordFromStateById(state: UserStateData, action: PayloadAction<{ userList: any[], deleteRecId: string | number }>) {
            let tempList: any[] = action.payload.userList || [];
            let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
            state.userList = finalPayloadList;
            state.totalCount = finalPayloadList.length;
        },
        getMasters(state: any, action: PayloadAction<{ masters: any[] }>) {
            state.masters = action.payload.masters;
        },
        getRosterMaster(state: any, action: PayloadAction<{ rosterMasterList: any[] }>) {
            state.rosterMasterList = action.payload.rosterMasterList;
        },
        setProjectMasterList(state: any, action: PayloadAction<{ projectMasterList: any[] }>) {
            state.projectMasterList = action.payload.projectMasterList;
        },
        setShiftMasterList(state: any, action: PayloadAction<{ shiftMasterList: any[] }>) {
            state.shiftMasterList = action.payload.shiftMasterList;
        },
        getEmployeeData(state: UserStateData, action: PayloadAction<{ selectedEmployee: any }>) {
            state.selectedEmployee = action.payload.selectedEmployee
        },
        setAttendanceModalOpenStatus(state: UserStateData, action: PayloadAction<{ attendanceModalOpenStatus: boolean }>) {
            state.attendanceModalOpenStatus = action.payload.attendanceModalOpenStatus
        },
        setAtncCommentsLoader(state: UserStateData, action: PayloadAction<any>) {
            state.atncCommentsLoader = action.payload.atncCommentsLoader
        },
        setAtncLeaveStatsLoader(state: UserStateData, action: PayloadAction<any>) {
            state.atncLeaveStatsLoader = action.payload.atncLeaveStatsLoader
        },
        setAtncCommentsList(state: UserStateData, action: PayloadAction<{ atncCommentsList: any[] }>) {
            state.atncCommentsList = action.payload.atncCommentsList
        },
        setAtncLeaveStatsList(state: UserStateData, action: PayloadAction<{ atncLeaveStatsList: any[] }>) {
            state.atncLeaveStatsList = action.payload.atncLeaveStatsList
        },
        setSelectedEmployeeObject(state: UserStateData, action: PayloadAction<{ selectedEmployeeObject: any }>) {
            state.selectedEmployeeObject = action.payload.selectedEmployeeObject
        },
        setExitedEmployeeData(state: UserStateData, action: PayloadAction<{ selectedExitedEmployee: any }>) {
            state.selectedExitedEmployee = action.payload.selectedExitedEmployee
        },
        setUploadCsvLoader(state: UserStateData, action: PayloadAction<{ uploadCsvLoader: boolean }>) {
            state.uploadCsvLoader = action.payload.uploadCsvLoader
        },
        setUploadedBulkEmployeeResponseObject(state: UserStateData, action: PayloadAction<{ uploadedBulkEmployeeResponseObject: any }>) {
            state.uploadedBulkEmployeeResponseObject = action.payload.uploadedBulkEmployeeResponseObject;
        },
        setUploadedBulkPhotoObject(state: UserStateData, action: PayloadAction<{ uploadedBulkPhotoObject: any }>) {
            state.uploadedBulkPhotoObject = action.payload.uploadedBulkPhotoObject;
        }
    }
});

export const reducer = slice.reducer;

export const getAllDropdownUsers = (projectId = ""): AppThunk => async (dispatch) => {
    let query = (projectId !== "") ? projectId : ""
    const response: any = await AXIOS_GET(`/api/user/drop-down-list?project=${query}`, {}, {});
    let usersList: any[] = [];
    let usersTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data];
        usersTotalCount = response?.data.length || 0;

        usersList = currentList.map(element => {
            return { label: `${element?.employeeName} - ${element?.empCode}`, value: element?.id || null }
        });
    }

    return { usersList: usersList || [], usersTotalCount: usersTotalCount }
};

export const getAllUsers = (page: number = 0, limit: number = 5, searchKey: string = '', project: string = '', shiftTiming: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/user?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&project=${project}&shiftTiming=${shiftTiming}`, {}, {});

    if (response) {
        dispatch(slice.actions.getUsersList({ userList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const getAllExitUsers = (page: number = 0, limit: number = 5, searchKey: string = '', project: string = '', shiftTiming: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/user/get-exit-users?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&project=${project}&shiftTiming=${shiftTiming}`, {}, {});

    dispatch(slice.actions.setExitedUsersList({ exitedUserList: (response?.data && response?.data.length) ? [...response?.data] : [], totalCount: response?.totalCount || 0 }))


    dispatch(slice.actions.loader({ loader: false }));
};

export const saveUser = (payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/users', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        history.replace(`/app/user/create-user/${response?.data.id}`);
        dispatch(slice.actions.getUserSingleData({ selectedUserObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateUser = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/users/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.getUserSingleData({ selectedUserObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getUserById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/users/' + id, {}, {});

    if (response?.id) {
        dispatch(slice.actions.getUserSingleData({ selectedUserObject: response }))
    }
};

export const deleteUserById = (userList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/user/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
        // dispatch(slice.actions.deleteListRecordFromStateById({userList: userList, deleteRecId: id}));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;

};

export const getRoles = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/role', {}, {});

    if (response) {
        dispatch(slice.actions.getRoleList({ roleList: response?.data || [] }));
    }
};

export const uploadUserImage = (payload): AppThunk => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', payload);

    const response: any = await AXIOS_POST('/api/users/upload', formData, {});

    if (response?.data) {
        // toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data ? (response?.data) : '';
};

export const updateUserProfileDetails = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/v1/auth/me`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(me());
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateUserPassword = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST(`/api/v1/auth/reset-new-password`, payload, {});

    if (response?.status === true) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }
};

/************************ Employee ******************** */

// Get masters for employee form
export const getMasters = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/master`, {}, {});

    if (response) {
        dispatch(slice.actions.getMasters({ masters: response?.data || {} }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const getRosterMaster = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/master/roster`, {}, {});

    if (response) {
        dispatch(slice.actions.getRosterMaster({ rosterMasterList: (response?.data && response?.data.length) ? [...response?.data] : [] }));
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const getProjectMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/atnc-project/_all`, {}, {});
    dispatch(slice.actions.setProjectMasterList({ projectMasterList: [...response?.data] }))
};

export const getShiftMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/shift-time/_all`, {}, {});
    dispatch(slice.actions.setShiftMasterList({ shiftMasterList: [...response?.data] }))
};

// Add new employee
export const addEmployee = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.loader({ loader: true }));
        const response: any = await AXIOS_POST(`/api/user`, payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            return { status: true, response: response?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            return { status: false, response: null };

        }
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: true, data: null };

    } finally {
        dispatch(slice.actions.loader({ loader: false }));
    }
};

// Get Emplayee Detail By Id
export const getEmployeeById = (employeeId): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/user/${employeeId}`, {}, {});
    if (response) {
        dispatch(slice.actions.getEmployeeData({ selectedEmployee: response?.data || {} }))
    }
    dispatch(slice.actions.loader({ loader: false }));
};

export const getExitedEmployeeById = (employeeId): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/user/get-exit-user/${employeeId}`, {}, {});

    dispatch(slice.actions.setExitedEmployeeData({ selectedExitedEmployee: response?.data?.id ? response?.data : null }))

    dispatch(slice.actions.loader({ loader: false }));
};

// Clear Selected Employee
export const clearSelectedEmployee = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.getEmployeeData({ selectedEmployee: null }))
};

// Updtae employee by ID
export const updateEmployeeById = (payload): AppThunk => async (dispatch) => {

    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_PATCH(`/api/user/${payload.id}`, payload, {});

    dispatch(slice.actions.loader({ loader: false }));

    if (response?.data) {
        toastr.success('Success', response?.message);
        return { status: true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status: false, response: response?.data }

    }
};

// Add warning to user
export const addEmployeeWarning = (payload): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_POST(`/api/warning`, payload, {});
    dispatch(slice.actions.loader({ loader: false }));
    if (response?.data) {
        toastr.success('Success', response?.message);
        return { status: true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status: false, response: response?.data }
    }
};

// Get Emplayee Detail By Id
export const unlinkImage = (id, url): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/warning/file/unlink/${id}?url=${url}`, {}, {});
    if (response) {
        toastr.success('Success', "Successfully deleted");
        dispatch(slice.actions.loader({ loader: false }));
        return { status: true }
    } else {
        return { status: true }
    }

};

export const updateEmployeeWarning = (id: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_PATCH(`/api/warning/${id}`, payload, {});
    dispatch(slice.actions.loader({ loader: false }));
    if (response && response.status) {
        toastr.success('Success', response?.message);
        return { status: true, response: {} }
    } else {
        toastr.error('Error', response?.message);
        return { status: false, response: {} }
    }
};

export const deleteEmployeeWarningFile = (id: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/warning/delete-multiple/${id}`, {}, {});
    if (response?.data) {
        return { status: true, response: response?.data }
    } else {
        return { status: false, response: {} }
    }
};

export const deleteEmployeeWarningData = (id: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE(`/api/warning/${id}`, {});
    if (response?.data) {
        return { status: true, response: response?.data }
    } else {
        return { status: false, response: {} }
    }
};

export const getAttendanceLeaveStats = (employeeId: any, fromDate: any, toDate: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAtncLeaveStatsLoader({ atncLeaveStatsLoader: true }));

        const response: any = await AXIOS_GET(`/api/attendance/get-counts/${employeeId}?fromDate=${fromDate}&toDate=${toDate}`, {}, {});
        let responseList: any[] = response?.data && response?.data.length ? response?.data : [];
        dispatch(slice.actions.setAtncLeaveStatsList({ atncLeaveStatsList: [...responseList] }));

        if (!responseList.length) snackBar('No records found', snackbarGlobConfig('info'));
        return { status: true, data: [...responseList] };

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setAtncLeaveStatsList({ atncLeaveStatsList: [] }));
        return { status: true, data: [] };

    } finally {
        dispatch(slice.actions.setAtncLeaveStatsLoader({ atncLeaveStatsLoader: false }));
    }
};

export const getAttendanceComments = (employeeId: any, fromDate: any, toDate: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAtncCommentsLoader({ atncCommentsLoader: true }));

        const response: any = await AXIOS_GET(`/api/attendance/get-comments/${employeeId}?fromDate=${fromDate}&toDate=${toDate}`, {}, {});

        let responseList: any[] = response?.data && response?.data.length ? response?.data : [];
        dispatch(slice.actions.setAtncCommentsList({ atncCommentsList: [...responseList] }));
        if (!responseList.length) snackBar('No records found', snackbarGlobConfig('info'));
        return { status: true, data: [...responseList] };

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setAtncCommentsList({ atncCommentsList: [] }));
        return { status: true, data: [] };

    } finally {
        dispatch(slice.actions.setAtncCommentsLoader({ atncCommentsLoader: false }));
    }
};

export const bulkInsertEmployees = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setUploadCsvLoader({ uploadCsvLoader: true }));
        const formData = new FormData();
        formData.append('file', payload);
        
        const response: any = await AXIOS_POST('/api/user/import-employee', formData, {});
        if (response && response?.data) {
            toastr.success('Success', response?.message);
            dispatch(slice.actions.setUploadedBulkEmployeeResponseObject({ uploadedBulkEmployeeResponseObject: response?.data }));
            return { status: true, response: response?.data }
        } else {
            toastr.error('Error', response?.message);
            dispatch(slice.actions.setUploadedBulkEmployeeResponseObject({ uploadedBulkEmployeeResponseObject: null }));
            return { status: false, response: {} }
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: true, data: [] };

    } finally {
        dispatch(slice.actions.setUploadCsvLoader({ uploadCsvLoader: false }));
    }
};

export const bulkUploadPhotos = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setUploadCsvLoader({ uploadCsvLoader: true }));
        const formData = new FormData();
        payload.forEach(file => {
            formData.append('employee-photos', file);
        });
        const response: any = await AXIOS_POST('/api/users/upload/employee-photos', formData, {}); 
        if (response && response.status && response.status == true ) {
            toastr.success('Success', response?.message);
            dispatch(slice.actions.setUploadedBulkPhotoObject({ uploadedBulkPhotoObject: response?.data }));
            return { status: true, response: response?.data }
        } else {
            toastr.error('Error', response?.data?.message);
            dispatch(slice.actions.setUploadedBulkPhotoObject({ uploadedBulkPhotoObject: null }));
            return { status: false, response: response?.data }
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: true, data: [] };

    } finally {
        dispatch(slice.actions.setUploadCsvLoader({ uploadCsvLoader: false }));
    }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getUserSingleData({ selectedUserObject: null }));
}

export const setAgentModalOpenStatus = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setAgentModalOpenStatus({ agentModalOpenStatus: status }));
}

export const setExitedEmployeeModalOpenStatus = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setExitedEmployeeModalOpenStatus({ exitedEmployeeModalOpenStatus: status }));
}

export const setAttendanceModalOpenStatus = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setAttendanceModalOpenStatus({ attendanceModalOpenStatus: status }));
}

export const setSelectedEmployeeObject = (inputObject: any) => (dispatch) => {
    dispatch(slice.actions.setSelectedEmployeeObject({ selectedEmployeeObject: _.isEmpty(inputObject) ? null : inputObject }));
}

export const clearAtncStatsList = () => (dispatch) => {
    dispatch(slice.actions.setAtncLeaveStatsList({ atncLeaveStatsList: [] }));
}

export const clearAtncCommentsList = () => (dispatch) => {
    dispatch(slice.actions.setAtncCommentsList({ atncCommentsList: [] }));
}

export const setUploadCsvLoader = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setUploadCsvLoader({ uploadCsvLoader: status }));
}

/* #endregion custom functions */

export default slice;
