/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  UserX as UsersXIcon,
  FileText as FileTextIcon,
  Aperture as ApertureIcon,
  Grid as GridIcon,
  Globe as GlobeIcon,
  UserCheck as UserCheckIcon,
  Tool as ToolIcon,
  Airplay as AirplayIcon,
  CheckSquare as CheckSquareIcon,
  Activity as ActivityIcon,
  Smartphone as SmartphoneIcon,
  Settings as SettingIcon,
  Mail as mailSetting,
  Clipboard as ClipboardIcon,
  Star as TeamLeadIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { getnavList } from 'src/slices/navbar';
import { useDispatch, useSelector } from 'src/store';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sectionsOld: Section[] = [
  // {
  //   subheader: 'Reports',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: '/app/reports/dashboard'
  //     },
  //     {
  //       title: 'Dashboard Alternative',
  //       icon: BarChartIcon,
  //       href: '/app/reports/dashboard-alternative'
  //     },
  //   ]
  // },
  {
    subheader: 'Applications',
    // items:[
    //   {
    //     title: 'Job Queue',
    //     icon: UsersIcon,
    //     href: '/app/management/customers'
    //   },
    //   {
    //     title: 'Dashboard Alternative',
    //     icon: BarChartIcon,
    //     href: '/app/reports/dashboard-alternative'
    //   }
    // ]
    items: [
      {
        title: 'Job Queue',
        icon: UsersIcon,
        href: '/app/jobqueue'
      },
      {
        title: 'Notifications',
        icon: UsersIcon,
        href: '/app/management/customers',
      },
      {
        title: 'Settings',
        icon: UsersIcon,
        href: '/app/management/customers',
      },
      {
        title: 'Tasks',
        icon: UsersIcon,
        href: '/app/management/customers',
      },
      {
        title: 'Tickets',
        icon: UsersIcon,
        href: '/app/ticket',
      },
      {
        title: 'Modules',
        icon: GridIcon,
        href: '/app/module'
      },
      {
        title: 'Projects',
        icon: GlobeIcon,
        href: '/app/project',
      },
      {
        title: 'Knowledge Base',
        icon: GlobeIcon,
        href: '/app/knowledgebase',
      },
      {
        title: 'User',
        icon: UsersIcon,
        href: '/app/user',
      },
      // {
      //   title: 'Role',
      //   icon: UserCheckIcon,
      //   href: '/app/role',
      // },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
    ]
  },
  {
    subheader: 'Module',
    items: [
      {
        title: 'Module 1',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      }
    ]
  },
  {
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          },
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  let keyVal: any = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={keyVal}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={keyVal}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 60
  },
  desktopDrawer: {
    width: 60,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    // cursor: 'pointer',
    // width: 84,
    // height: 84
  },
  menuWrap: {
    padding: '0px 12px'
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {

  let navitems = [
    {
      title: 'Attendance',
      icon: CalendarIcon,
      href: '/app/attendance',
    },
    {
      title: 'Attendance View',
      icon: ApertureIcon,
      href: '/app/attendance-view',
    },
    {
      title: 'Roster',
      icon: ClipboardIcon,
      href: '/app/roster',
    },
    {
      title: 'Employee',
      icon: UserCheckIcon,
      href: '/app/employee',
    },
    {
      title: 'Exit Resource',
      icon: UsersXIcon,
      href: '/app/exited-employee',
    },
    {
      title: 'Reports',
      icon: FileTextIcon,
      href: '/app/attendance-report',
    },
    {
      title: 'Role',
      icon: ToolIcon,
      href: '/app/role',
    },
    {
      title: 'Settings',
      icon: SettingIcon,
      // href: '/app/category',
      href: '/app/masters'
      // items: [
      //   {
      //     title: 'Master',
      //     icon: LayoutIcon,
      //     href: '/app/management/sos',
      //     items: [
      //       {
      //         title: 'Dashboard',
      //         icon: PieChartIcon,
      //         href: '/app/reports/dashboard'
      //       },
      //       {
      //         title: 'Dashboard Alternative',
      //         icon: BarChartIcon,
      //         href: '/app/reports/dashboard-alternative'
      //       },
      //     ]
      //   },
      // ]
    },
    {
      title: 'Mail Setting',
      icon: mailSetting,
      href: '/app/mail-setting'
    },
    {
      title: 'Team Master',
      icon: TeamLeadIcon,
      href: '/app/team-master'
    },
    // {
    //   title: 'Admin',
    //   icon: UserPlusIcon,
    //   href:'/app/adminmodule'
    // },
    // {
    //   title: 'Appointment',
    //   icon: CheckSquareIcon,
    //   href:'/app/appointment'
    // },
    // {
    //   title: 'Lead',
    //   icon: AirplayIcon,
    //   href:'/app/lead'
    // },
    // {
    //   title: 'Contact',
    //   icon: SmartphoneIcon,
    //   href:'/app/contact'
    // },
    // {
    //   title: 'Task',
    //   href: '/app/kanban',
    //   icon: TrelloIcon
    // },
  ]

  let sections: Section[] = [
    {
      subheader: '',
      items: [
        // {
        //   title: 'Dashboard',
        //   icon: PieChartIcon,
        //   href: '/app/reports/dashboard'
        // },
        // {
        //   title: 'Dashboard Alternative',
        //   icon: BarChartIcon,
        //   href: '/app/reports/dashboard-alternative'
        // },
      ]
    },
    // {
    //   subheader: '',
    //   items: [
    //     {
    //       title: 'Master',
    //       icon: LayoutIcon,
    //       href: '/app/management/sos',
    //       items: [
    //         {
    //           title: 'Dashboard',
    //           icon: PieChartIcon,
    //           href: '/app/reports/dashboard'
    //         },
    //         {
    //           title: 'Dashboard Alternative',
    //           icon: BarChartIcon,
    //           href: '/app/reports/dashboard-alternative'
    //         },
    //       ]
    //     },
    //   ]
    // },
    {
      subheader: '',
      items: []
    }
  ];

  const classes = useStyles();
  const location = useLocation();
  // const { user } = useAuth();
  const { navList, navListItems, defaultListItems, defaultListItemsNav, dynamicListItemsNav } = useSelector(state => state.navItems)
  const { roleList } = useSelector(state => state.projectModule);
  const { user } = useSelector(state => state.auth);

  const setSidebarDefault = () => {
    // userProfile?.userRole?.name != 'Team Lead'
    for (let navObj of navitems) {
      if (navObj?.title === 'Attendance') {
        navObj['href'] = (user?.userRole?.name === 'Team Lead') ? '/app/attendance-tl' : '/app/attendance';
      }
    }

    let list = [];
    let data3 = defaultListItemsNav.map(i => i.moduleName);
    for (let i = 0; i < navitems.length; i++) {
      if (data3.includes(navitems[i].title)) {
        list.push(navitems[i])
      }
    }

    list.map(i => { sections[1].items.push(i) });
  }

  const setSidebarDynamic = () => {
    var list = []
    var data2 = navListItems.map(i => i.moduleName)
    for (let i = 0; i < navList.length; i++) {
      if (data2.includes(navList[i].title)) {
        list.push(navList[i])
      }
    }
    console.log(list)
    list.map(i => { sections[1].items.push(i) })
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const setNavItems = () => {
    setSidebarDefault();
    // setSidebarDynamic();
    dynamicListItemsNav.map(i => { sections[1].items.push(i) });
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {
        user && (<PerfectScrollbar options={{ suppressScrollX: true }}>
          <Hidden lgUp>
            <Box
              p={2}
              display="flex"
              justifyContent="center"
            >
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
          </Hidden>
          {/* <Box > */}
          {/* <Box display="flex" justifyContent="center">
          

              <Avatar alt="User" className={classes.avatar} src={user.photo}
                />
            </Box> */}
          <Box
            // mt={2}
            textAlign="center"
          >
            {/* <Link
                component={RouterLink}
                to="/app/account"
                variant="h5"
                color="textPrimary"
                underline="none"
              >
                {user.name}
              </Link> */}
            {/* <Typography
                variant="body2"
                color="textSecondary"
              >
                Your tier:
                {' '}
                <Link
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.tier}
                </Link>
              </Typography> */}
          </Box>
          {/* </Box> */}
          {/* <Divider /> */}
          <Box className={classes.menuWrap}>
            {setNavItems()}
            {sections.map((section, index) => (
              <List key={index} subheader={(
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              )}>
                {renderNavItems({ items: section.items, pathname: location.pathname })}
              </List>
            ))}
          </Box>
          <Divider />
          {/* <Box p={2}>
            <Box
              p={2}
              borderRadius="borderRadius"
              bgcolor="background.dark"
            >
              <Typography
                variant="h6"
                color="textPrimary"
              >
                Need Help?
              </Typography>
              <Link
                variant="subtitle1"
                color="secondary"
                component={RouterLink}
                to="/docs"
              >
                Check our docs
              </Link>
            </Box>
          </Box> */}
        </PerfectScrollbar>)
      }

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        {
          user && (
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              {content}
            </Drawer>
          )
        }

      </Hidden>
      <Hidden mdDown>
        {
          user && (<Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>)
        }

      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
