import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';

interface DynamicModule {
  moduleList: any[],
  page: number,
  limit: number,
  totalCount: number,
  formConfig: any,
  selectedJobValue: any,
  loader: boolean,
  iframe: any,
  formUrlPath: string,
  formUrlSuffix: string,
  deleteRecId: any
}


const initialState: DynamicModule = {
  moduleList: [],
  page: 1,
  limit: 10,
  totalCount: 0,
  formConfig: null,
  selectedJobValue: null,
  loader: false,
  iframe: '',
  formUrlPath: null,
  formUrlSuffix: null,
  deleteRecId: null
};


const slice = createSlice({
  name: 'DynamicModule',
  initialState,
  reducers: {
    getListData(state: DynamicModule, action: PayloadAction<{ jobData: any[], totalCount: number, formConfig: any, formPayload: any }>) {
      state.moduleList = action.payload.jobData;
      state.totalCount = action.payload.totalCount;
      state.formConfig = action.payload.formConfig;
      // state.selectedJobValue = action.payload.formPayload;
    },

    getModuleDetails(state: DynamicModule, action: PayloadAction<{ formConfig: any, url: any, project: any, formUrlSuffix: string }>) {
      state.formConfig = action.payload.formConfig;
      state.iframe = action.payload.url
      // state.formUrlSuffix = action.payload.formUrlSuffix

      if (action.payload.project?.urlSuffix) {
        let formUrlPath: string = `${window.location.origin}/form/${action.payload.project?.urlSuffix}`;
        state.formUrlPath = formUrlPath
      } else {
        state.formUrlPath = null;
      }
    },
    updateFormUrlSuffix(state: DynamicModule, action: PayloadAction<{ formUrlSuffix: string }>) {
      state.formUrlSuffix = action.payload.formUrlSuffix
    },
    setDeleteRecordId(state: DynamicModule, action: PayloadAction<{ deleteRecId: string | number }>) {
      state.deleteRecId = action.payload.deleteRecId
    },
    loader(state: DynamicModule, action: PayloadAction<any>) {
      state.loader = action.payload.loader
    },
    getFormData(state: DynamicModule, action: PayloadAction<any>) {
      state.selectedJobValue = action.payload.formPayload;
      // state.formUrlSuffix = action.payload.formPayload.uuid
      // add urlSuffix here
    },
    updateModuleList(state: DynamicModule, action: PayloadAction<{ id: any, list: any[] }>) {
      let filteredArray = action.payload.list.filter(item => item.id != action.payload.id)
      state.moduleList = filteredArray
    }
  }
})


export const reducer = slice.reducer;


export const getList = (id: number, page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loader({ loader: true }))
  const response: any = await AXIOS_GET(`/api/project-module-value/module/${id}?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});
  if (response) {
    dispatch(slice.actions.getListData({ jobData: response.data.list, totalCount: response.data?.totalCount || 0, formConfig: response.data.columnName, formPayload: response.data.list[0]?.formPayload }));
  }
  dispatch(slice.actions.loader({ loader: false }))
};

export const getModuleById = (id): AppThunk => async (dispatch) => {
  console.log("get getProjectModule api call")
  dispatch(slice.actions.loader({ loader: true }))
  const response: any = await AXIOS_GET('/api/project-module/findone/' + id, {}, {});
  if (response.status) {
    dispatch(slice.actions.getModuleDetails({
      formConfig: response?.data?.formPayload,
      url: response.data?.iframeUrl || '',
      project: response.data?.project || '',
      formUrlSuffix: response?.data?.formUrlSuffix || '',
    }));
  } else {

  }
  dispatch(slice.actions.loader({ loader: false }))
};

export const saveModule = (payload, history): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_POST('/api/project-module-value', payload, {});

  if (response?.status) {
    toastr.success('Success', response?.message);
    // dispatch(slice.actions.getJobData({ jobData: response.data.jobValue, formConfig: response.data.formConfig.formPayload }));
    history.push(`/app/custommodule/list/${response?.data?.module}`)
  } else {
    toastr.error('Error', response?.message);

  }
};

export const getDymanicModuleData = (id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/project-module-value/find/' + id, {}, {});
  console.log("responses dynamic module get one data", response.data.formPayload)
  if (response) {
    dispatch(slice.actions.getFormData(response.data));
    dispatch(slice.actions.updateFormUrlSuffix({formUrlSuffix:response.data.uuid}))
  } else {

  }
};

export const updateProjectModule = (id, payload, history, historyid): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_PATCH('/api/project-module-value/' + id, payload, {});
  console.log("responses", response)
  if (response) {
    history.push(`/app/custommodule/list/${historyid}`)
  } else {

  }
};

export const deleteModuleById = (datalist, id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_DELETE('/api/project-module-value/' + id, {});

  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

  if (response?.data?.isDeleted) {
    toastr.success('Success', response?.message);
  } else {
    toastr.error('Error', response?.message);
  }

  return response?.data?.isDeleted == true ? true : false;
};

export const generateFormURL = (id: string): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_PATCH('/api/project-module-value/form/generateFormURL/' + id, {}, {});

  dispatch(slice.actions.updateFormUrlSuffix({ formUrlSuffix: response?.data?.uuid || null }));

  if (response?.status) {
    toastr.success('Success', response?.message);
  } else {
    toastr.error('Error', response?.message);
  }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
  dispatch(slice.actions.getFormData({ formPayload: null }))
}
/* #endregion custom functions */

export default slice;