import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const getToken = () => {
  const token = localStorage.getItem('accessToken')
  return token;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  // const { isAuthenticated } = useAuth();
  const token = getToken();
  // check if token is there. if no, move it to login

  if (!token) {
    return <Redirect to="/login" />;
  } else {
    return (
      <>
        {children}
      </>
    );
  }

  // return (
  //   <>
  //     {children}
  //   </>
  // );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
