import axios, { AxiosError } from "axios";

// const BASE_URL = 'https://5f6e-103-224-35-106.ngrok.io';
// export const BASE_URL = 'http://96a0-103-146-24-6.ngrok.io'
// export const BASE_URL = 'http://localhost:3800';
// export const BASE_URL = 'http://172.20.6.110:3800';
// export const BASE_URL = 'http://172.20.6.160:3800';
// export const BASE_URL = 'http://172.20.6.160:3800';
// export const BASE_URL = 'http://172.20.6.184:3800';
export const BASE_URL = 'https://attendance-api.wait-wise.com';
// export const BASE_URL = 'https://staging-attendance.wait-wise.com/api';
// export const BASE_URL = 'http://172.20.6.17:3800';
export const SSO_LOGIN_URL = 'https://sso.wait-wise.com/app-login';
    
const getToken = () => {
    const innertoken = localStorage.getItem('accessToken')
    const token: string = "Bearer " + innertoken
    return token
}

const clearToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.location.href = '/login';
}

export const AXIOS_POST = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'POST',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });

        return response.data;
    } catch (error) {
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }

            return error.response;
        } else {
            return null
        }
    }
}

export const AXIOS_GET = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'GET',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            params: body
        });
        return response.data
    } catch (error) {
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }

            return error.response
        } else {
            return null
        }
    }
}


export const AXIOS_PATCH = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'PATCH',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}

export const AXIOS_DELETE = async (url: string, headers: any) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}