import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_POST } from './service/apiService';
import { ShiftAllStateData } from 'src/types/masters';

const initialState: ShiftAllStateData = {
    shiftList: [],
    shiftTotalCount: 0,
    selectedShiftObject: null,
    loader: false,
    deleteRecId: null,
    isModalOpen: false,
    shiftFormType: 'Add'
};

const slice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        setShiftList(state: ShiftAllStateData, action: PayloadAction<{ shiftList: any[], shiftTotalCount: number, clear: boolean }>) {
            const currentShiftList: any[] = current(state).shiftList;
            state.shiftTotalCount = action.payload.shiftTotalCount;

            if (action.payload.clear) {
                state.shiftList = action.payload.shiftList;
            } else {
                state.shiftList = [...currentShiftList, ...action.payload.shiftList];
            }
        },
        setSelectedShiftObject(state: ShiftAllStateData, action: PayloadAction<{ selectedShiftObject: any }>) {
            state.selectedShiftObject = action.payload.selectedShiftObject
        },
        setModalOpenOrCloseStatus(state: ShiftAllStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: ShiftAllStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: ShiftAllStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setShiftFormType(state: ShiftAllStateData, action: PayloadAction<{ shiftFormType: string }>) {
            state.shiftFormType = action.payload.shiftFormType
        },

    }
});

export const reducer = slice.reducer;

export const getShiftList = (page: number = 0, limit: number = 5, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/shift-time?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setShiftList({
        shiftList: response?.data && response?.data.length ? [...response?.data] : [],
        shiftTotalCount: response?.totalCount || 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const createShift = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/shift-time', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, { variant: 'success' });
        dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, { variant: 'error' });
    }

    return response?.data?.id ? true : false;
};

export const getShiftById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/shift-time/' + id, {}, {});

    dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const deleteShiftById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/shift/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        snackBar(response?.message, { variant: 'success' });
    } else {
        snackBar(response?.message, { variant: 'error' });
    }

    return response?.status == true ? true : false;

};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const setShiftFormType = (shiftFormType: string = 'Add') => (dispatch) => {
    dispatch(slice.actions.setShiftFormType({ shiftFormType: shiftFormType }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;
