import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as JobQueueReducer } from 'src/slices/jobqueue';
import { reducer as ProjectModuleReducer } from 'src/slices/projectModule';
import { reducer as projectsReducer } from 'src/slices/projects';
import { reducer as userReducer } from 'src/slices/users';
import { reducer as roleReducer } from 'src/slices/roles';
import { reducer as toastrReducer } from 'react-redux-toastr';
import {reducer as NavItems} from 'src/slices/navbar';
import {reducer as DynamicModule} from 'src/slices/dynamicModule';
import {reducer as AuthModule} from 'src/slices/auth';
import {reducer as AdminModule} from 'src/slices/adminModule';
import {reducer as LeadReducer} from 'src/slices/leads';
import {reducer as ContactReducer} from 'src/slices/contacts';
import {reducer as KnowledgeBaseReducer} from 'src/slices/knowledgeBase';
import {reducer as KanbanTaskReducer} from 'src/slices/kanbanTask';
import { reducer as sideBarSkillsReducer } from 'src/slices/sideBarSkils';
import { reducer as QAReducer } from 'src/slices/QA';
import { reducer as MasterReducer } from 'src/slices/masters';
import { reducer as Categoryeducer } from 'src/slices/category';
import { reducer as AttendanceReducer } from 'src/slices/attendance';
import { reducer as AttendanceTLReducer } from 'src/slices/attendanceTL';
import { reducer as AttendanceReportReducer } from 'src/slices/attendanceReport';
import { reducer as shiftReducer } from 'src/slices/shift';
import { reducer as projectReducer } from 'src/slices/project';
import { reducer as mailSettingReducer } from 'src/slices/mailSetting';
import { reducer as teamMasterReducer } from 'src/slices/teamMasterSlice';
import { reducer as AttendanceRosterReducer } from 'src/slices/attendanceRoster';
import { reducer as DesignationReducer } from 'src/slices/designation';


const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  jobQueue: JobQueueReducer,
  projectModule: ProjectModuleReducer,
  navItems: NavItems,
  projects: projectsReducer,
  users: userReducer,
  roles: roleReducer,
  toastr: toastrReducer,
  dynamicmodule : DynamicModule,
  auth:AuthModule,
  adminModule: AdminModule,
  leads: LeadReducer,
  contacts: ContactReducer,
  knowledgeBase: KnowledgeBaseReducer,
  kanbanTask: KanbanTaskReducer,
  sideBarSkills: sideBarSkillsReducer,
  QA: QAReducer,
  master: MasterReducer,
  category: Categoryeducer,
  attendance: AttendanceReducer,
  attendanceTL: AttendanceTLReducer,
  attendanceRoster: AttendanceRosterReducer,
  attendanceReport: AttendanceReportReducer,
  shift: shiftReducer,
  project: projectReducer,
  mailSetting: mailSettingReducer,
  teamMaster: teamMasterReducer,
  designation: DesignationReducer
});

export default rootReducer;
