import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { snackbarGlobConfig } from 'src/globalConfig';
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { AttendanceReportState } from 'src/types/attendanceReport';

const initialState: AttendanceReportState = {
    monthWiseProjectData: null,
    monthWiseProjectLoader: false,
    monthWiseAttendanceLoader: false,
    monthWiseAttendanceData: null,
    dayWiseStatus: null,
    dayWiseStatusLoader: false,
    employeeMasters: null,
    projectMasterList: [],
    dateRangeAttendance: [],
    projectWiseStatus: null,
    logReportTotalCount: 0,
    modifiedByList: [],
    onBoardStatusList: [],
    onBoardStatusCount: 0,
    onBoardLoder: false,
    attritionReportList: []
};

const slice = createSlice({
    name: 'attendanceReport',
    initialState,
    reducers: {

        setMonthWiseProjectData(state: AttendanceReportState, action: PayloadAction<{ monthWiseProjectData: any }>) {
            state.monthWiseProjectData = action.payload.monthWiseProjectData;
        },
        setDayWiseStatus(state: AttendanceReportState, action: PayloadAction<{ dayWiseStatus: any }>) {
            state.dayWiseStatus = action.payload.dayWiseStatus;
        },
        setProjectWiseStatus(state: AttendanceReportState, action: PayloadAction<{ projectWiseStatus: any }>) {
            state.projectWiseStatus = action.payload.projectWiseStatus;
        },
        setOnboardStatus(state: AttendanceReportState, action: PayloadAction<{ onBoardStatusList: any, onBoardStatusCount: number  }>) {
            state.onBoardStatusList = action.payload.onBoardStatusList;
            state.onBoardStatusCount = action.payload.onBoardStatusCount;
        },
        setMonthWiseProjectLoader(state: AttendanceReportState, action: PayloadAction<{ monthWiseProjectLoader: boolean }>) {
            state.monthWiseProjectLoader = action.payload.monthWiseProjectLoader;
        },
        setMonthWiseAttendanceLoader(state: AttendanceReportState, action: PayloadAction<{ monthWiseAttendanceLoader: boolean }>) {
            state.monthWiseAttendanceLoader = action.payload.monthWiseAttendanceLoader;
        },
        setMonthWiseAttendanceData(state: AttendanceReportState, action: PayloadAction<{ monthWiseAttendanceData: any, totalCount: number }>) {
            state.monthWiseAttendanceData = action.payload.monthWiseAttendanceData;
            state.logReportTotalCount = action.payload.totalCount;
        },
        setDayWiseStatusLoader(state: AttendanceReportState, action: PayloadAction<{ dayWiseStatusLoader: boolean }>) {
            state.dayWiseStatusLoader = action.payload.dayWiseStatusLoader;
        },
        setOnBpardStatusLoader(state: AttendanceReportState, action: PayloadAction<{ onBoardLoder: boolean }>) {
            state.onBoardLoder = action.payload.onBoardLoder;
        },
        setEmployeeMasters(state: any, action: PayloadAction<{ employeeMasters: any }>) {
            state.employeeMasters = action.payload.employeeMasters;
        },
        setProjectMasterList(state: any, action: PayloadAction<{ projectMasterList: any[] }>) {
            state.projectMasterList = action.payload.projectMasterList;
        },
        setDateRangeAttendance(state: any, action: PayloadAction<{ dateRangeAttendance: any[] }>) {
            state.dateRangeAttendance = action.payload.dateRangeAttendance;
        },
        setModifiedByList(state: any, action: PayloadAction<{ modifiedByList: any[] }>) {
            state.modifiedByList = action.payload.modifiedByList;
        },
        setAttritionStatus(state: AttendanceReportState, action: PayloadAction<{ attritionReportList: any}>) {
            state.attritionReportList = action.payload.attritionReportList;
        }
    }
});

export const getMonthlyProjectData = (year: string = '', month: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setMonthWiseProjectLoader({ monthWiseProjectLoader: true }));
        const response: any = await AXIOS_GET(`/api/attendance/get-project-attendance?month=${month || ''}&year=${year || ''}`, {}, {});

        if (response?.data) {
            dispatch(slice.actions.setMonthWiseProjectData({ monthWiseProjectData: response?.data }));
            return { status: true, data: response?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setMonthWiseProjectData({ monthWiseProjectData: null }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setMonthWiseProjectLoader({ monthWiseProjectLoader: false }));
    }
};

export const getMonthlyAttendanceLogData = (date: string = '', searchKey: string = '', project = '',modifiedBy = '', page = 0, limit = 10, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setMonthWiseAttendanceLoader({ monthWiseAttendanceLoader: true }));
        const response: any = await AXIOS_GET(`/api/attendance-log/monthly-attendance-log?specificDate=${date || ''}&searchKey=${searchKey || ''}&project=${project || ''}&modifiedBy=${modifiedBy || ''}&page=${page + 1 || ''}&limit=${limit || ''}`, {}, {});

        if (response?.data) {
            dispatch(slice.actions.setMonthWiseAttendanceData({ monthWiseAttendanceData: response?.data, totalCount: response?.totalCount || 0 }));
            return { status: true, data: response?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setMonthWiseAttendanceData({ monthWiseAttendanceData: null, totalCount: response?.totalCount || 0 }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setMonthWiseAttendanceLoader({ monthWiseAttendanceLoader: false }));
    }
};

export const getDayWiseStatus = (year: string = '', month: string = '', day: string, project: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setDayWiseStatusLoader({ dayWiseStatusLoader: true }));
        const response: any = await AXIOS_GET(`/api/attendance/get-attendance-by-date?year=${year || ''}&month=${month || ''}&day=${day || ''}&project=${project || ''}`, {}, {});

        if (response?.data) {
            dispatch(slice.actions.setDayWiseStatus({ dayWiseStatus: response?.data }));
            return { status: true, data: response?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setDayWiseStatus({ dayWiseStatus: null }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setDayWiseStatusLoader({ dayWiseStatusLoader: false }));
    }
};

export const getProjectWiseStatus = (year: string = '', month: string = '', day: string, project: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setDayWiseStatusLoader({ dayWiseStatusLoader: true }));
        const response: any = await AXIOS_GET(`/api/attendance/get-summary-attendance?year=${year || ''}&month=${month || ''}&day=${day || ''}&project=${project || ''}`, {}, {});

        if (response?.data) {

            dispatch(slice.actions.setProjectWiseStatus({ projectWiseStatus: response?.data }));
            return { status: true, data: response?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setProjectWiseStatus({ projectWiseStatus: null }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setDayWiseStatusLoader({ dayWiseStatusLoader: false }));
    }
};

export const getOnboardReport = (page: any, limit: any, searchKey: any, onBoardDate: any, onBoardEndDate:any, orderBy: any, order: any,snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/onboardReport?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&date=${onBoardDate}&endDate=${onBoardEndDate}&orderBy=${orderBy}&order=${order}`, {}, {});
        if (response?.data) {
            dispatch(slice.actions.setOnboardStatus({ onBoardStatusList: response?.data, onBoardStatusCount: response?.totalCount || 0 }));
            return { status: true, data: response?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setOnboardStatus({ onBoardStatusList: null, onBoardStatusCount: response?.totalCount || 0 }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: false }));
    }
};

export const sentOnboardingReportMail = (onBoardDate: any, onBoardEndDate:any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_GET(`/api/user/mail/onboardReport?searchKey=${searchKey}&date=${onBoardDate}&endDate=${onBoardEndDate}`, {}, {});
        return response?.data || false;

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };
    } 
};

export const getAttendanceReportByDate = (startDate: string = '', endDate: string = '', project: any, shiftTime: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        // console.log('==== shiftTime ', shiftTime)
        const response: any = await AXIOS_GET(`/api/attendance/get-attendance-by-date-range?startDate=${startDate || ''}&endDate=${endDate || ''}&project=${project || ''}&shiftTime=${shiftTime || ''}`, {}, {});
        if (response?.data && response?.data.length) {
            dispatch(slice.actions.setDateRangeAttendance({ dateRangeAttendance: response?.data }));
            return { status: true, data: response?.data };
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setDateRangeAttendance({ dateRangeAttendance: [] }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setDayWiseStatusLoader({ dayWiseStatusLoader: false }));
    }
};

export const getEmployeeMasters = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/master`, {}, {});
    dispatch(slice.actions.setEmployeeMasters({ employeeMasters: response?.data || null }));
    return { status: response?.status, data: response?.data };
};

export const getProjectMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/atnc-project/_all`, {}, {});
    dispatch(slice.actions.setProjectMasterList({ projectMasterList: [...response?.data] }));
    return { status: true, data: [...response?.data] };
};
export const getModifiedByList = (selectedDate: string = ''): AppThunk => async (dispatch) => { 
    const response: any = await AXIOS_GET(`/api/attendance-log/modifiedByList?specificDate=${selectedDate || ''}`, {}, {});
    dispatch(slice.actions.setModifiedByList({ modifiedByList: [...response?.data] }));
    return { status: true, data: [...response?.data] };
};

export const getAttritionReport = (year: any, project: any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/attrition/report?year=${year}&project=${project}&searchKey=${searchKey}`, {}, {});
        if (response?.data) {
            dispatch(slice.actions.setAttritionStatus({ attritionReportList: response?.data?.data }));
            return { status: true, data: response?.data?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            dispatch(slice.actions.setAttritionStatus({ attritionReportList: null }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };
    } 
};

export const getAttritionGenderReport = (year: any, project: any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/attrition/gender/report?year=${year}&project=${project}&searchKey=${searchKey}`, {}, {});
        if (response?.data) {
            return { status: true, data: response?.data?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };
    } 
};

export const getAttritionReasonReport = (year: any, project: any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/attrition/reason/report?year=${year}&project=${project}&searchKey=${searchKey}`, {}, {});
        if (response?.data) {
            return { status: true, data: response?.data?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };
    } 
};


export const getAttritionTenurityReport = (year: any, project: any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/attrition/tenurity/report?year=${year}&project=${project}&searchKey=${searchKey}`, {}, {});
        if (response?.data) {
            return { status: true, data: response?.data?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };
    } 
};


export const getAttritionTenurityReportList = (year: any, project: any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/attrition/tenurity/report/list?year=${year}&project=${project}&searchKey=${searchKey}`, {}, {});
        if (response?.data) {
            return { status: true, data: response?.data?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };
    } 
};

export const getAttritionOverallReport = (year: any, project: any, searchKey: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setOnBpardStatusLoader({ onBoardLoder: true }));
        const response: any = await AXIOS_GET(`/api/user/attrition/overall-report?year=${year}&project=${project}&searchKey=${searchKey}`, {}, {});
        if (response?.data) {
            return { status: true, data: response?.data?.data };

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };
    } 
};


export const reducer = slice.reducer;
export default slice;