import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import {toastr} from 'react-redux-toastr';
import { me } from './auth';
import { CategoryStateData } from 'src/types/masters';


const initialState: CategoryStateData = {
    categoryList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    loader: false,
    sideModalOpenStatus: false,
    selectedCategory: null,
    categoryMasters: [],
    categorySearchText: '',
    categoryMasterSelected: null
};

const slice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        getCategoryMasters(state: CategoryStateData, action: PayloadAction<{ categoryMasters: any }>) {
            state.categoryMasters = action.payload.categoryMasters
        },
        getCategoryList(state: CategoryStateData, action: PayloadAction<{ categoryList: any, totalCount: number }>) {
            state.categoryList = action.payload.categoryList
            state.totalCount = action.payload.totalCount;
        },
        getCategoryData(state: CategoryStateData, action: PayloadAction<{ selectedCategory: any }>) {
            state.selectedCategory = action.payload.selectedCategory
        },
        loader(state: CategoryStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setSideModalOpenStatus(state: CategoryStateData, action: PayloadAction<{ sideModalOpenStatus: boolean }>) {
            state.sideModalOpenStatus = action.payload.sideModalOpenStatus
        },
        setCatergoryTableSearch(state: CategoryStateData, action: PayloadAction<{ categorySearchText: string }>) {
            state.categorySearchText = action.payload.categorySearchText
        },
        setCatergoryMasterSelected(state: CategoryStateData, action: PayloadAction<{ categoryMasterSelected: any }>) {
            state.categoryMasterSelected = action.payload.categoryMasterSelected
        },
    }
});

// Side modal Open / Hide
export const setSideModalOpenStatus = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setSideModalOpenStatus({ sideModalOpenStatus: status }));
}

// Set Table Search text
export const setCatergoryTableSearch = (text: string = '') => (dispatch) => {
    dispatch(slice.actions.setCatergoryTableSearch({ categorySearchText: text }));
}
// Set Master Filter Selected value
export const setCatergoryMasterSelected = (master: any = '') => (dispatch) => {
    dispatch(slice.actions.setCatergoryMasterSelected({ categoryMasterSelected: master }));
}

// Category Master List (Default 0 - 100)
export const getCategoryMasters = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/master/data?page=1&limit=100`, {}, {});
    if (response) {
        dispatch(slice.actions.getCategoryMasters({ categoryMasters: response?.data}))
    }

    dispatch(slice.actions.loader({ loader: false }));
};


// Category List
export const getCategoryList = (page: number = 0, limit: number = 5, searchKey: string = '', master: number | string  = ''): AppThunk => async (dispatch) => {
    console.log('response', page)

    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/category?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&master=${master || ''}`, {}, {});
    console.log('response', response)

    if (response) {
        dispatch(slice.actions.getCategoryList({ categoryList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

// Category Detail by ID
export const getCategoryById = (id): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/Category/${id}`, {}, {});

    if (response) {
        dispatch(slice.actions.getCategoryData({ selectedCategory: response?.data || {} }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

// Clear Selected Category
export const setSelectedCategory = (category: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.getCategoryData({ selectedCategory: category}))
};


// Clear Selected Category
export const clearSelectedCategory = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.getCategoryData({ selectedCategory: null}))
};

// Add Category To Master
export const addCategoryToMaster = (payload):  AppThunk => async (dispatch) => {
    
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_POST(`/api/category`, payload, {});

    dispatch(slice.actions.loader({ loader: false }));

    if (response?.data ) {
        toastr.success('Success', response?.message);
        return { status : true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status : false, response: response?.data }

    }
};


// Updtae category by ID
export const updateCategoryById = (payload):  AppThunk => async (dispatch) => {
    
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_PATCH(`/api/category/${payload.id}`, payload, {});

    dispatch(slice.actions.loader({ loader: false }));

    if (response?.data ) {
        toastr.success('Success', response?.message);
        return { status : true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status : false, response: response?.data }

    }
};

// Delete category by ID
export const deleteCategoryById = (id):  AppThunk => async (dispatch) => {
    
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_DELETE(`/api/category/${id}`, {});

    dispatch(slice.actions.loader({ loader: false }));

    if (response?.data ) {
        toastr.success('Success', response?.message);
        return { status : true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status : false, response: response?.data }

    }
};


export const reducer = slice.reducer;
export default slice;