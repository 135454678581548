import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { AttendanceStateData } from 'src/types/attendance';
import { snackbarGlobConfig } from 'src/globalConfig';


const initialState: AttendanceStateData = {
    attendanceTableData: [],
    selectedEmpInfo: null,
    loader: false,
    empInfoModalOpenStatus: false,
    attendanceInfoModalOpenStatus: false,
    atncTableDisable: false,
    atncLockStatus: false,
    atncLockSwitchLoader: false,
    circularProgressIndexObject: null,
    dateForCircularProgress: null,
    atncInfoCreationLoader: false,
    attendanceLeaveInfo: null,
    employeePopupRowId: null,
    searchTableLoader: false,
    userProfile: null
};

const slice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {

        getAttendanceList(state: AttendanceStateData, action: PayloadAction<{ attendanceTableData: any }>) {
            state.attendanceTableData = action.payload.attendanceTableData;
        },
        setUserProfile(state: AttendanceStateData, action: PayloadAction<{ userProfile: any }>) {
            state.userProfile = action.payload.userProfile;
        },
        updatedAttendanceTableDataLocal(state: AttendanceStateData, action: PayloadAction<{ rowIndex: any, columnIndex: any, payload: any }>) {
            const attendanceTableData = state.attendanceTableData;
            attendanceTableData[action.payload.rowIndex].attendance[action.payload.columnIndex] = { ...action.payload.payload };

            state.attendanceTableData = attendanceTableData;
        },
        loader(state: AttendanceStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader;
        },
        setEmpInfoModalOpenStatus(state: AttendanceStateData, action: PayloadAction<any>) {
            state.empInfoModalOpenStatus = action.payload.empInfoModalOpenStatus;
        },
        setAttendanceInfoModalOpenStatus(state: AttendanceStateData, action: PayloadAction<any>) {
            state.attendanceInfoModalOpenStatus = action.payload.attendanceInfoModalOpenStatus
        },
        setSelectedEmpInfo(state: AttendanceStateData, action: PayloadAction<{ selectedEmpInfo: any }>) {
            state.selectedEmpInfo = action.payload.selectedEmpInfo;
        },
        setAtncTableDisable(state: AttendanceStateData, action: PayloadAction<any>) {
            state.atncTableDisable = action.payload.atncTableDisable;
        },
        setAtncLockStatus(state: AttendanceStateData, action: PayloadAction<any>) {
            state.atncLockStatus = action.payload.atncLockStatus;
        },
        setAtncLockSwitchLoader(state: AttendanceStateData, action: PayloadAction<any>) {
            state.atncLockSwitchLoader = action.payload.atncLockSwitchLoader;
        },
        setCircularProgressIndexObject(state: AttendanceStateData, action: PayloadAction<any>) {
            state.circularProgressIndexObject = action.payload.circularProgressIndexObject;
        },
        setDateForCircularProgress(state: AttendanceStateData, action: PayloadAction<any>) {
            state.dateForCircularProgress = action.payload.dateForCircularProgress;
        },
        setAtncInfoCreationLoader(state: AttendanceStateData, action: PayloadAction<any>) {
            state.atncInfoCreationLoader = action.payload.atncInfoCreationLoader;
        },
        setAttendanceLeaveInfo(state: AttendanceStateData, action: PayloadAction<any>) {
            state.attendanceLeaveInfo = action.payload.attendanceLeaveInfo;
        },
        setCircularProgressIdforEmployee(state: AttendanceStateData, action: PayloadAction<any>) {
            state.employeePopupRowId = action.payload.employeePopupRowId;
        },
        setSearchTableLoader(state: AttendanceStateData, action: PayloadAction<any>) {
            state.searchTableLoader = action.payload.searchTableLoader
        },
        updatedDateWiseAttendanceTableDataLocal(state: AttendanceStateData, action: PayloadAction<{ columnWiseAtnc: any }>) {
            let attendanceTableData = JSON.parse(JSON.stringify(state.attendanceTableData));
            let modifiedAtncTableData = JSON.parse(JSON.stringify(state.attendanceTableData));
            let updatedAtncResponseData = JSON.parse(JSON.stringify(action.payload.columnWiseAtnc));

            updatedAtncResponseData.forEach(element => {

                attendanceTableData.filter(ele => {
                    ele['attendance'].some(item => {
                        if (item?.id == element?.id) {
                        }
                    })
                });
            });
        },
    }
});

// Attendance Table Data
export const getAttendanceTableData = (payload, snackBar): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setSearchTableLoader({ searchTableLoader: true }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: true }));
        const response: any = await AXIOS_GET(`/api/user/attendance?page=1&limit=1000&year=${payload?.year || ''}&month=${payload?.month || ''}&project=${payload?.project || ''}&shiftTiming=${payload?.shiftTiming || ''}&search=${payload?.search || ''}&desigination=${payload?.desigination || ''}&location=${payload?.location || ''}&teamLeadId=${payload?.teamLeadId || ""}`, {}, {});

        if (response?.data && response?.data.length) {
            dispatch(slice.actions.getAttendanceList({ attendanceTableData: response?.data }));
            return { status: true, data: response?.data };

        } else {
            snackBar('No Records Available', snackbarGlobConfig('error'));
            dispatch(slice.actions.getAttendanceList({ attendanceTableData: [] }));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };

    } finally {
        dispatch(slice.actions.setSearchTableLoader({ searchTableLoader: false }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: false }));
    }
};

export const getAttendanceTableDataForTLLogin = (payload, snackBar): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setSearchTableLoader({ searchTableLoader: true }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: true }));
        const response: any = await AXIOS_GET(`/api/user/attendance/view?page=1&limit=100&year=${payload?.year || ''}&month=${payload?.month || ''}&project=${payload?.project || ''}&shiftTiming=${payload?.shiftTiming || ''}&search=${payload?.search || ''}&desigination=${payload?.desigination || ''}&location=${payload?.location || ''}&teamLeadId=${payload?.teamleadId || ""}`, {}, {});

        if (response?.data && response?.data.length) {
            dispatch(slice.actions.getAttendanceList({ attendanceTableData: response?.data }));
            return { status: true, data: response?.data };

        } else {
            snackBar('No Records Available', snackbarGlobConfig('error'));
            dispatch(slice.actions.getAttendanceList({ attendanceTableData: [] }));
            return { status: false, data: [] };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: [] };

    } finally {
        dispatch(slice.actions.setSearchTableLoader({ searchTableLoader: false }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: false }));
    }
};

export const getUserProfile = (snackBar: any): AppThunk => async (dispatch) => {
    try {

        const response: any = await AXIOS_GET('/api/v1/auth/me', {}, {});
        if (response?.data?.id) {
            dispatch(slice.actions.setUserProfile({ userProfile: response?.data }));
            return { status: true, data: response?.data };
        } else {
            dispatch(slice.actions.setUserProfile({ userProfile: null }));
            return { status: false, data: null };
        }

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally { }
}

export const updatedAttendanceTableDataLocal = (rowIndex, columnIndex, payload): AppThunk => async (dispatch) => {
    dispatch(slice.actions.updatedAttendanceTableDataLocal({ rowIndex, columnIndex, payload }))
};

export const getEmployeeSingleData = (id: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCircularProgressIdforEmployee({ employeePopupRowId: id }));
        const response: any = await AXIOS_GET(`/api/user/${id}`, {}, {});

        if (!response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('error'));

        }

        dispatch(slice.actions.setSelectedEmpInfo({ selectedEmpInfo: response?.data?.id ? response?.data : null }));

        return response?.data?.id ? true : false;

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCircularProgressIdforEmployee({ employeePopupRowId: null }));
    }
}

export const markAttendace = (payload, indexObject, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCircularProgressIndexObject({ circularProgressIndexObject: indexObject }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: true }));

        const response: any = await AXIOS_POST(`/api/attendance`, payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            return { status: true, data: response?.data }
        } else {
            snackBar(response?.message || response?.statusText, snackbarGlobConfig('error'));
            return { status: false, data: response?.data }

        }
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setCircularProgressIndexObject({ circularProgressIndexObject: null }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: false }));
    }
}

export const markBulkAttendace = (payload, date,project, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setDateForCircularProgress({ dateForCircularProgress: date }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: true }));

        const response: any = await AXIOS_POST(`/api/attendance/bulk-insert?project=${project}`, payload, {});
        // dispatch(slice.actions.updatedDateWiseAttendanceTableDataLocal({ columnWiseAtnc: response }));

      
        if (response?.data && response?.data?.length) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            return { status: true, data: response?.data }

        } else {
            snackBar(response?.message || response?.statusText, snackbarGlobConfig('error'));
            return { status: false, data: [] }
        }
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setDateForCircularProgress({ dateForCircularProgress: null }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: false }));
    }
}

export const updateAttendace = (id: any, payload, indexObject,project, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCircularProgressIndexObject({ circularProgressIndexObject: indexObject }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: true }));

        const response: any = await AXIOS_PATCH(`/api/attendance/${id}?project=${project}`, payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            return { status: true, data: response?.data }
        } else {
            snackBar(response?.message || response?.statusText, snackbarGlobConfig('error'));
            return { status: false, data: response?.data }

        }
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setCircularProgressIndexObject({ circularProgressIndexObject: null }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: false }));
    }
}

export const getAttendanceLeaveInfo = (id: any, indexObject, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCircularProgressIndexObject({ circularProgressIndexObject: indexObject }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: true }));

        const response: any = await AXIOS_GET(`/api/attendance/leave-type/${id}`, {}, {});

        if (response?.data?.id) {
            dispatch(slice.actions.setAttendanceLeaveInfo({ attendanceLeaveInfo: response?.data }));
            return { status: true, response: response?.data };
        } else {
            snackBar(response?.message || response?.statusText, snackbarGlobConfig('error'));
            dispatch(slice.actions.setAttendanceLeaveInfo({ attendanceLeaveInfo: null }));
            return { status: false, response: response?.data }

        }
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setAttendanceLeaveInfo({ attendanceLeaveInfo: null }));
        return false;

    } finally {
        dispatch(slice.actions.setCircularProgressIndexObject({ circularProgressIndexObject: null }));
        dispatch(slice.actions.setAtncTableDisable({ atncTableDisable: false }));
    }
}

export const updateAttendaceLeaveInfo = (id: any, payload: any,project:string, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAtncInfoCreationLoader({ atncInfoCreationLoader: true }));

        const response: any = await AXIOS_PATCH(`/api/attendance/${id}?project=${project}`, payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message || response?.statusText, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? { status: true, data: response?.data } : { status: false, data: null };

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: false, data: null };

    } finally {
        dispatch(slice.actions.setAtncInfoCreationLoader({ atncInfoCreationLoader: false }));
    }
};

export const updateAtncLockStatus = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAtncLockSwitchLoader({ atncLockSwitchLoader: true }));

        const response: any = await AXIOS_POST('/api/attendance/update-lock-status', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            dispatch(slice.actions.setAtncLockStatus({ atncLockStatus: response?.data?.isLock == true ? true : false }));
        } else {
            snackBar(response?.message || response?.statusText, snackbarGlobConfig('error'));
            dispatch(slice.actions.setAtncLockStatus({ atncLockStatus: !payload?.isLock }));
        }

        return response?.data?.id ? (response?.data?.isLock == true ? true : false) : (!payload?.isLock);

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setAtncLockStatus({ atncLockStatus: !payload?.isLock }));
        return (!payload?.isLock);

    } finally {
        dispatch(slice.actions.setAtncLockSwitchLoader({ atncLockSwitchLoader: false }));
    }
};

export const getAtncLockStatus = (attendanceDate: any, assignProject: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAtncLockSwitchLoader({ atncLockSwitchLoader: true }));

        const response: any = await AXIOS_GET(`/api/attendance/get-lock-status?attendanceDate=${attendanceDate}&assignProject=${assignProject}`, {}, {});

        if (response?.data?.id) {
            dispatch(slice.actions.setAtncLockStatus({ atncLockStatus: response?.data?.isLock == true ? true : false }));
        } else {
            dispatch(slice.actions.setAtncLockStatus({ atncLockStatus: false }));
        }

        return response?.data?.isLock == true ? true : false;

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setAtncLockSwitchLoader({ atncLockSwitchLoader: false }));
    }
}

export const setEmpInfoModalOpenStatus = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setEmpInfoModalOpenStatus({ empInfoModalOpenStatus: status }));
}

export const setAttendanceInfoModalOpenStatus = (status: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setAttendanceInfoModalOpenStatus({ attendanceInfoModalOpenStatus: status }));
}

export const reducer = slice.reducer;
export default slice;