import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_POST } from './service/apiService';

const initialState = {
    designationList: [],
    designationTotalCount: 0,
    selectedDesignationObject: null,
    loader: false,
    deleteRecId: null,
    isModalOpen: false,
    designationFormType: 'Add'
};

const slice = createSlice({
    name: 'designation',
    initialState,
    reducers: {
        setDesignationList(state: any, action: PayloadAction<{ designationList: any[], designationTotalCount: number, clear: boolean }>) {
            const currentDesignationList: any[] = current(state).designationList;
            state.designationTotalCount = action.payload.designationTotalCount;

            if (action.payload.clear) {
                state.designationList = action.payload.designationList;
            } else {
                state.designationList = [...currentDesignationList, ...action.payload.designationList];
            }
        },
        setDesignationObject(state: any, action: PayloadAction<{ selectedDesignationObject: any }>) {
            state.selectedDesignationObject = action.payload.selectedDesignationObject
        },
        setModalOpenOrCloseStatus(state: any, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: any, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: any, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setDesignationFormType(state: any, action: PayloadAction<{ designationFormType: string }>) {
            state.designationFormType = action.payload.designationFormType
        },

    }
});

export const reducer = slice.reducer;

export const getDesignationList = (page: number = 0, limit: number = 5, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/designation?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});
    dispatch(slice.actions.setDesignationList({
        designationList: response?.data && response?.data.length ? [...response?.data] : [],
        designationTotalCount: response?.totalCount || 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const createDesignation = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/designation', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, { variant: 'success' });
        dispatch(slice.actions.setDesignationObject({ selectedDesignationObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, { variant: 'error' });
    }

    return response?.data?.id ? true : false;
};

export const getDesignationById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/designation/' + id, {}, {});

    dispatch(slice.actions.setDesignationObject({ selectedDesignationObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const deleteDesignationById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/designation/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        snackBar(response?.message, { variant: 'success' });
    } else {
        snackBar(response?.message, { variant: 'error' });
    }

    return response?.status == true ? true : false;

};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const setDesignationFormType = (designationFormType: string = 'Add') => (dispatch) => {
    dispatch(slice.actions.setDesignationFormType({ designationFormType: designationFormType }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setDesignationObject({ selectedDesignationObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;
