import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import {toastr} from 'react-redux-toastr';
import { me } from './auth';
import { MastersStateData } from 'src/types/masters';


const initialState: MastersStateData = {
    masterList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    loader: false,
    sideModalOpenStatus: false,
    selectedMaster: null,
    currentMenu: ''
};

const slice = createSlice({
    name: 'masters',
    initialState,
    reducers: {
        getMasterList(state: MastersStateData, action: PayloadAction<{ masterList: any[], totalCount: number }>) {
            state.masterList = action.payload.masterList;
            state.totalCount = action.payload.totalCount;
        },
        getMasterData(state: MastersStateData, action: PayloadAction<{ selectedMaster: any }>) {
            state.selectedMaster = action.payload.selectedMaster
        },
        loader(state: MastersStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setsideModalOpenStatus(state: MastersStateData, action: PayloadAction<{ sideModalOpenStatus: boolean }>) {
            state.sideModalOpenStatus = action.payload.sideModalOpenStatus
        },
        setCurrentMenu(state: MastersStateData, action: PayloadAction<{ currentMenu: string }>) {
            state.currentMenu = action.payload.currentMenu
        },
    }
});



// Masters List
export const getMasterList = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/master?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.getMasterList({ masterList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

// Master Detail by ID
export const getMasterById = (id): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/master/${id}`, {}, {});

    if (response) {
        dispatch(slice.actions.getMasterData({ selectedMaster: response?.data || {} }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

// Clear Selected Master
export const clearSelectedMaster = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.getMasterData({ selectedMaster: null}))
};

// Add Master
export const addMaster = (payload):  AppThunk => async (dispatch) => {
    
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_POST(`/api/master`, payload, {});

    dispatch(slice.actions.loader({ loader: false }));

    if (response?.data ) {
        toastr.success('Success', response?.message);
        return { status : true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status : false, response: response?.data }

    }
};


// Updtae Master by ID
export const updateMasterById = (payload):  AppThunk => async (dispatch) => {
    
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_PATCH(`/api/master/${payload.id}`, payload, {});

    dispatch(slice.actions.loader({ loader: false }));

    if (response?.data ) {
        toastr.success('Success', response?.message);
        return { status : true, response: response?.data }
    } else {
        toastr.error('Error', response?.message);
        return { status : false, response: response?.data }

    }
};

/* #region custom functions */
export const setCurrentMenu = (name: string = 'Category') => (dispatch) => {
    dispatch(slice.actions.setCurrentMenu({ currentMenu: name }));
}
/* #endregion custom functions */


export const reducer = slice.reducer;
export default slice;