import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { ProjectAllStateData } from 'src/types/masters';
import { snackbarGlobConfig } from 'src/globalConfig';
const initialState: ProjectAllStateData = {
    projectList: [],
    projectTotalCount: 0,
    shiftList: [],
    shiftTotalCount: 0,
    selectedProjectObject: null,
    loader: false,
    deleteRecId: null,
    isModalOpen: false,
    projectFormType: 'Add'
};

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjectList(state: ProjectAllStateData, action: PayloadAction<{ projectList: any[], projectTotalCount: number, clear: boolean }>) {
            const currentProjectList: any[] = current(state).projectList;
            state.projectTotalCount = action.payload.projectTotalCount;

            if (action.payload.clear) {
                state.projectList = action.payload.projectList;
            } else {
                state.projectList = [...currentProjectList, ...action.payload.projectList];
            }
        },
        setShiftList(state: ProjectAllStateData, action: PayloadAction<{ shiftList: any[], shiftTotalCount: number }>) {
            state.shiftList = action.payload.shiftList;
            state.shiftTotalCount = action.payload.shiftTotalCount;
        },
        setSelectedProjectObject(state: ProjectAllStateData, action: PayloadAction<{ selectedProjectObject: any }>) {
            state.selectedProjectObject = action.payload.selectedProjectObject
        },
        setModalOpenOrCloseStatus(state: ProjectAllStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: ProjectAllStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: ProjectAllStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setProjectFormType(state: ProjectAllStateData, action: PayloadAction<{ projectFormType: string }>) {
            state.projectFormType = action.payload.projectFormType
        },

    }
});

export const reducer = slice.reducer;

export const getProjectList = (page: number = 0, limit: number = 5, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/atnc-project?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setProjectList({
        projectList: response?.data && response?.data.length ? [...response?.data] : [],
        projectTotalCount: response?.totalCount || 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const updateProjectStatus = (id, status, snackBar): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_PATCH(`/api/atnc-project/update-status/${id}`, {isActive: status}, {});
    dispatch(slice.actions.loader({ loader: false }));   
    if(response && response.status){
        snackBar(response?.message, snackbarGlobConfig('success'));
    }else{
        snackBar(response?.message, snackbarGlobConfig('error'));
    }
    return response 
};


export const createProject = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/atnc-project', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success'));
        dispatch(slice.actions.setSelectedProjectObject({ selectedProjectObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }

    return response?.data?.id ? true : false;
};

export const getProjectById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/atnc-project/' + id, {}, {});

    dispatch(slice.actions.setSelectedProjectObject({ selectedProjectObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const deleteProjectById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/atnc-project/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));
    if (response?.status) {
        snackBar(response?.message, snackbarGlobConfig('success'));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }

    return response?.status == true ? true : false;

};

export const getShiftList = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/shift-time?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let shiftList: any[] = [];
    let shiftTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data];
        shiftTotalCount = response?.totalCount || 0;

        shiftList = currentList.map(element => {
            return { label: `${element?.shiftStartTime} - ${element?.shiftEndTime}`, value: element?.id || null }
        });
    }
    
    return { shiftList: shiftList || [], shiftTotalCount: shiftTotalCount }
};

export const getProjectistMaster = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/atnc-project?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let projectList: any[] = [];
    let projectTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data];
        projectTotalCount = response?.totalCount || 0;        

        projectList = currentList.map(element => {
            return { label: `${element?.projectName}`, value: element?.id || null, shiftTime: element?.shiftTime }
        });
    }
    
    return { projectList: projectList || [], projectTotalCount: projectTotalCount }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const setProjectFormType = (projectFormType: string = 'Add') => (dispatch) => {
    dispatch(slice.actions.setProjectFormType({ projectFormType: projectFormType }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedProjectObject({ selectedProjectObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;
